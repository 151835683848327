import React from "react"
import styled from "@emotion/styled"
import { withLayout } from "../components/Layout/layout.hocs"

export const NotFound = withLayout(
  { title: "Page Not Found", variant: "light" },
  () => {
    return (
      <Container>
        <h1>Page not found</h1>
      </Container>
    )
  }
)

const Container = styled("div")({
  padding: "4em",
  display: "flex",
  flexDirection: "column",
  gap: "2em",
})
