import { graphql } from "gatsby"
import { NotFound } from "../NotFound/NotFound"

export default NotFound

export const query = graphql`
  query NotFoundPage {
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }
  }
`
